import React from 'react';
import {
  Ad,
  Announce,
  Article,
  BarChart,
  Briefcase,
  Compliance,
  Cube,
  Cubes,
  Gift,
  Group,
  List,
  Stakeholder,
  TextWrap,
  Tree,
  Workshop,
  Waypoint,
  Car,
  Cluster,
  LocationPin,
  Vend,
  Integration,
  Phone,
  Image,
  ContactInfo,
  History
} from 'grommet-icons';
import { mdiTransitConnectionVariant } from '@mdi/js';
import Icon from '@mdi/react';
import { isItemNew } from '../utils';
import AdGroup from '../components/icons/ad-group';

export const SURVEY = 'survey';
export const AD = 'ad';
export const PAGE = 'page';
export const CONTENT_BLOCK = 'contentblock';
export const FLOW = 'flow';
export const USER_ATTRIBUTE = 'userattribute';
export const PROFILE_ATTRIBUTE = 'profileattribute';
export const REWARD = 'reward';
export const CAMPAIGN = 'campaign';
export const ADVERTISER = 'advertiser';
export const INTEGRATION = 'integration';
export const EMAIL_SUPPRESSION_LIST = 'emailsuppressionlist';
export const WEB_API_INTEGRATION = 'webapiintegration';
export const AUDIENCE = 'audience';
export const AUDIENCE_PRIORITY = 'audiencepriority';
export const AD_GROUP = 'adgroup';
export const AD_CREATIVE = 'creative';
export const REPORT = 'report';
export const JOBS_BROWSER = 'jobsbrowser';
export const DATA_CAMPAIGN = 'datacampaign';
export const DATA_DELIVERY = 'datadelivery';
export const DATA_DELIVERY_GROUP = 'datadeliverygroup';
export const JOB_CAMPAIGN = 'jobcampaign';
export const CALL_CAMPAIGN = 'callcampaign';
export const JOB_AD_GROUP = 'jobadgroup';
export const JOB_AD_CREATIVE = 'jobadcreative';
export const TRAFFIC_SOURCE = 'trafficsource';
export const TRAFFIC_PARTNER = 'trafficpartner';
export const MINION_LOG = 'log';
export const CAMPAIGN_PLACEMENT = 'campaignplacement';
export const RULE = 'rule';
export const EMBEDDED_FLOW_CONFIG = 'embeddedflowconfig';
export const VERTICAL = 'vertical';
export const BUSINESS_UNIT = 'businessunit';
export const OFFER_SYNDICATION_CONFIG = 'offersyndicationconfig';
export const CHANGE_HISTORY = 'changehistory';

export const itemTypeNames = {
  [SURVEY]: 'Survey',
  [AD]: 'Ad',
  [CAMPAIGN]: 'Campaign',
  [ADVERTISER]: 'Advertiser',
  [INTEGRATION]: 'Integration',
  [PAGE]: 'Page',
  [CONTENT_BLOCK]: 'Content Block',
  [FLOW]: 'Flow',
  [USER_ATTRIBUTE]: 'User Attribute',
  [PROFILE_ATTRIBUTE]: 'Profile Attribute',
  [REWARD]: 'Reward',
  [AUDIENCE]: 'Audience',
  [AUDIENCE_PRIORITY]: 'Audience Priority',
  [AD_GROUP]: 'Ad Group',
  [AD_CREATIVE]: 'Ad Creative',
  [REPORT]: 'Report',
  [JOBS_BROWSER]: 'Jobs Browser',
  [DATA_CAMPAIGN]: 'Data Campaign',
  [DATA_DELIVERY]: 'Data Delivery',
  [DATA_DELIVERY_GROUP]: 'Data Delivery Group',
  [JOB_CAMPAIGN]: 'Job Campaign',
  [CALL_CAMPAIGN]: 'Call Campaign',
  [JOB_AD_GROUP]: 'Job Ad Group',
  [JOB_AD_CREATIVE]: 'Job Ad Creative',
  [TRAFFIC_SOURCE]: 'Traffic Source',
  [TRAFFIC_PARTNER]: 'Traffic Partner',
  [MINION_LOG]: 'Log',
  [CAMPAIGN_PLACEMENT]: 'Campaign Placement',
  [RULE]: 'Rule',
  [EMBEDDED_FLOW_CONFIG]: 'Ad Unit Config',
  [VERTICAL]: 'Vertical',
  [BUSINESS_UNIT]: 'Business Unit',
  [OFFER_SYNDICATION_CONFIG]: 'Offer Syndication Config',
  [CHANGE_HISTORY]: 'Change History'
};

export const itemTypeIcons = {
  [SURVEY]: <Compliance />,
  [AD]: <Ad />,
  [CAMPAIGN]: <Announce />,
  [ADVERTISER]: <Workshop />,
  [PAGE]: <Article />,
  [CONTENT_BLOCK]: <TextWrap />,
  [FLOW]: <Tree />,
  [USER_ATTRIBUTE]: <Stakeholder />,
  [PROFILE_ATTRIBUTE]: <ContactInfo />,
  [REWARD]: <Gift />,
  [AUDIENCE]: <Group />,
  [AUDIENCE_PRIORITY]: <List />,
  [REPORT]: <BarChart />,
  [JOBS_BROWSER]: <Vend />,
  [DATA_CAMPAIGN]: <Waypoint />,
  [DATA_DELIVERY]: <Cube />, //todo:
  [DATA_DELIVERY_GROUP]: <Cubes />,
  [JOB_CAMPAIGN]: <Briefcase />,
  [CALL_CAMPAIGN]: <Phone />,
  [TRAFFIC_SOURCE]: <Car />,
  [TRAFFIC_PARTNER]: <Cluster />,
  [MINION_LOG]: <></>, //todo
  [CAMPAIGN_PLACEMENT]: <LocationPin />,
  [INTEGRATION]: <Integration />,
  [RULE]: <Icon path={mdiTransitConnectionVariant} size={1} />,
  [EMBEDDED_FLOW_CONFIG]: <Integration />,
  [AD_GROUP]: <AdGroup />,
  [AD_CREATIVE]: <Image />,
  [OFFER_SYNDICATION_CONFIG]: <Integration />,
  [CHANGE_HISTORY]: <History />
};

export const itemTypesByReadModel = {
  userattributereadmodel: USER_ATTRIBUTE,
  profileattributereadmodel: PROFILE_ATTRIBUTE,
  contentblockreadmodel: CONTENT_BLOCK,
  pagereadmodel: PAGE,
  surveyreadmodel: SURVEY,
  flowreadmodel: FLOW,
  rewardreadmodel: REWARD,
  campaignreadmodel: CAMPAIGN,
  advertiserreadmodel: ADVERTISER,
  integrationreadmodel: INTEGRATION,
  webapiintegrationreadmodel: WEB_API_INTEGRATION,
  audiencereadmodel: AUDIENCE,
  audiencepriorityreadmodel: AUDIENCE_PRIORITY,
  adgroupreadmodel: AD_GROUP,
  adcreativereadmodel: AD_CREATIVE,
  reportreadmodel: REPORT,
  jobsbrowserreadmodel: JOBS_BROWSER,
  rulereadmodel: RULE,
  datacampaignreadmodel: DATA_CAMPAIGN,
  datadeliveryreadmodel: DATA_DELIVERY,
  datadeliverygroupreadmodel: DATA_DELIVERY_GROUP,
  jobcampaignreadmodel: JOB_CAMPAIGN,
  callcampaignreadmodel: CALL_CAMPAIGN,
  jobadgroupreadmodel: JOB_AD_GROUP,
  jobadcreativereadmodel: JOB_AD_CREATIVE,
  trafficsourcereadmodel: TRAFFIC_SOURCE,
  trafficpartnerreadmodel: TRAFFIC_PARTNER,
  campaignplacementreadmodel: CAMPAIGN_PLACEMENT,
  embeddedflowconfigreadmodel: EMBEDDED_FLOW_CONFIG,
  verticalreadmodel: VERTICAL,
  businessunitreadmodel: BUSINESS_UNIT,
  offersyndicationconfigreadmodel: OFFER_SYNDICATION_CONFIG
};

export const buildApiPath = params => {
  const { id, type } = params;
  let base = `/api`;

  if (type === DATA_DELIVERY) {
    base += `/${DATA_CAMPAIGN}/${params.campaignid}`;
  }

  if (type === INTEGRATION) {
    base += `/${ADVERTISER}/${params.advertiserid}`;
  }

  if (type === EMBEDDED_FLOW_CONFIG || type === OFFER_SYNDICATION_CONFIG) {
    base += `/${TRAFFIC_PARTNER}/${params.trafficpartnerid}`;
  }

  if (type === AD_GROUP || type === AD_CREATIVE) {
    base += `/${CAMPAIGN}/${params.campaignid}`;
  }

  return `${base}/${type}${!isItemNew(id) ? `/${id}` : ''}`;
};

export const getReadModelFromItemType = itemType => {
  for (var key in itemTypesByReadModel) {
    if (itemTypesByReadModel[key] === itemType) {
      return key;
    }
  }
};

export const ITEM_TYPES_WITH_HISTORY = [REPORT];
