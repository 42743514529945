import React from 'react';
import { Box, Text } from 'grommet';
import {
  StatusGood,
  StatusCritical,
  StatusInfo,
  CircleAlert,
  Close
} from 'grommet-icons';

const MESSAGE_TYPES = ['info', 'alert', 'success', 'error'];

const MessageBox = ({
  type = 'info',
  message = '',
  onDismiss,
  customMessage
}) => {
  if (!MESSAGE_TYPES.includes(type)) {
    type = 'info';
  }

  let icon;
  let style = {
    border: '1px solid',
    borderRadius: '3px',
    padding: '12px 8px',
    margin: '10px 0px',
    color: '#333'
  };

  switch (type) {
    case 'info':
      icon = <StatusInfo color='#00739D' />;
      style.background = '#e6f7ff';
      style['borderColor'] = '#00739D';
      break;
    case 'alert':
      icon = <CircleAlert color='#FFAA15' />;
      style.background = '#fffbe6';
      style['borderColor'] = '#FFAA15';
      break;
    case 'success':
      icon = <StatusGood color='#00C781' />;
      style.background = '#f6ffed';
      style['borderColor'] = '#00C781';
      break;
    case 'error':
      icon = <StatusCritical color='#FC4043' />;
      style.background = '#fff2f0';
      style['borderColor'] = '#FC4043';
      break;
    default:
      icon = <StatusInfo color='#00739D' />;
      style.background = '#e6f7ff';
      style['borderColor'] = '#00739D';
      break;
  }

  return (
    <Box direction='row' gap='small' align='center' style={style}>
      {icon}
      {customMessage ? (
        customMessage
      ) : (
        <Text alignSelf='center'>{message}</Text>
      )}
      {onDismiss && (
        <Close
          style={{
            width: '12px',
            height: '12px',
            position: 'relative',
            top: '-5px',
            left: '3px'
          }}
          onClick={onDismiss}
        />
      )}
    </Box>
  );
};

MessageBox.displayName = 'MessageBox';

export default MessageBox;
