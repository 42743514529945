import { createAction } from '@reduxjs/toolkit';
import * as itemTypes from '../../../items/item-types';
import {
  getItem as getItemInternal,
  saveItem as saveItemInternal
} from '../../../items/item.actions';
import { keyFromItemType } from '../../../routing-utils';

export const DEFAULT_CAMPAIGN_PLACEMENT = {
  id: null,
  name: null
};

export const receiveOfferSyndicationConfig = createAction(
  'receiveOfferSyndicationConfig'
);
export const setOfferSyndicationConfigIsBusy = createAction(
  'setOfferSyndicationConfigIsBusy'
);
export const modifyOfferSyndicationConfig = createAction(
  'modifyOfferSyndicationConfig'
);
export const closeOfferSyndicationConfig = createAction(
  'closeOfferSyndicationConfig'
);

export const OfferSyndicationConfigModel = (id, name, params) => ({
  id,
  name,
  type: itemTypes.OFFER_SYNDICATION_CONFIG,
  campaignPlacements: [DEFAULT_CAMPAIGN_PLACEMENT],
  [keyFromItemType(itemTypes.TRAFFIC_PARTNER)]: params.trafficpartnerid
});

export const getItem = getItemInternal(
  OfferSyndicationConfigModel,
  receiveOfferSyndicationConfig,
  setOfferSyndicationConfigIsBusy
);

export const saveItem = (() => {
  return saveItemInternal(
    modifyOfferSyndicationConfig,
    item => {
      if (!item.trafficSourceId) {
        return 'Traffic Source is required.';
      }
      if (
        Array.isArray(item.campaignPlacements) &&
        item.campaignPlacements.some(filter => filter.id === null)
      ) {
        return 'Please select a Campaign Placement for Targeting.';
      }

      if (item.revSharePercent > 1 || item.revSharePercent < 0) {
        return 'Revenue Share must be between 0 and 100%.';
      }

      if (!item.configType) {
        return 'Please select whether this configuration is static or sorted.';
      }

      if (
        item.configType === 'SORTED' &&
        (item.numberOfAdOffers < 1 || item.numberOfAdOffers > 20)
      ) {
        if (item.numberOfAdOffers < 1) {
          return 'The number of offers should be at least 1.';
        }
        if (item.numberOfAdOffers > 20) {
          return 'The number of offers should be at most 20.';
        }
      }
    },
    async itemCopy => {
      const campaignPlacementIds = itemCopy.campaignPlacements.map(
        placement => placement.id
      );

      itemCopy.campaignPlacementIds = campaignPlacementIds;
      delete itemCopy.campaignPlacements;
    }
  );
})();
