export const buildTargeting = targeting => {
  if (!targeting) {
    return null;
  }

  const {
    sourceIdValues,
    excludedSourceIdValues,
    audienceMeet,
    excludedAudienceMeet,
    verticalIds,
    excludedVerticalIds,
    audienceIds,
    excludedAudienceIds,
    deviceTypes,
    locationIds,
    excludedLocationIds,
    zipCodes,
    excludedZipCodes,
    sessionAttributes,
    excludedSessionAttributes,
    attributesMeet,
    excludedAttributesMeet,
    profileAttributes,
    excludedProfileAttributes,
    profileAttributesApply,
    excludedProfileAttributesApply
  } = targeting;

  const newTargeting = {
    os: buildOSTargeting(deviceTypes),
    sourceIds: {
      include: sourceIdValues ?? null,
      exclude: excludedSourceIdValues ?? null
    },
    verticals: {
      include: verticalIds.length ? verticalIds : null,
      exclude: excludedVerticalIds.length ? excludedVerticalIds : null
    },
    audience: {
      includeMatchMode: audienceMeet,
      excludeMatchMode: excludedAudienceMeet,
      include: audienceIds.length ? audienceIds : null,
      exclude: excludedAudienceIds.length ? excludedAudienceIds : null
    },
    locations: {
      include: locationIds.length ? locationIds : null,
      exclude: excludedLocationIds.length ? excludedLocationIds : null
    },
    postalCodes: {
      include: zipCodes.length ? zipCodes : null,
      exclude: excludedZipCodes.length ? excludedZipCodes : null
    },
    profileAttributeConditions: {
      includeMatchMode: profileAttributesApply,
      excludeMatchMode: excludedProfileAttributesApply,
      include: buildProfileAttribute(profileAttributes),
      exclude: buildProfileAttribute(excludedProfileAttributes)
    },
    sessionAttributes: {
      includeMatchMode: attributesMeet,
      excludeMatchMode: excludedAttributesMeet,
      device: buildSessionAttribute(
        'DEVICE',
        sessionAttributes,
        excludedSessionAttributes
      ),
      queryParams: buildSessionAttribute(
        'QUERY_PARAM',
        sessionAttributes,
        excludedSessionAttributes
      ),
      profile: buildSessionAttribute(
        'PROFILE',
        sessionAttributes,
        excludedSessionAttributes
      ),
      partnerAttributes: buildSessionAttribute(
        'PARTNER',
        sessionAttributes,
        excludedSessionAttributes
      ),
      inferredAttributes: buildSessionAttribute(
        'INFERRED_ATTRIBUTES',
        sessionAttributes,
        excludedSessionAttributes
      )
    }
  };

  return newTargeting;
};

const buildOSTargeting = deviceTypes => {
  return deviceTypes.map(({ value, comparisonOperator, propertyValues }) => {
    const versionValue = propertyValues?.length ? propertyValues[0] : null;
    const COMPARISON_OPERATORS = {
      Equal: {
        minVersion: versionValue,
        maxVersion: versionValue
      },
      GreaterThanOrEqual: {
        minVersion: versionValue,
        maxVersion: null
      },
      LessThanOrEqual: {
        minVersion: null,
        maxVersion: versionValue
      }
    };
    return {
      ...COMPARISON_OPERATORS[comparisonOperator],
      platformName: value
    };
  });
};

const buildSessionAttribute = (
  attributeType,
  includedAttributes,
  excludedAttributes
) => {
  const mappedIncluded = includedAttributes
    .filter(a => a._type === attributeType)
    .map(sessionAttribute => {
      const {
        comparisonOperator,
        inverse,
        propertyName,
        propertyValues
      } = sessionAttribute;
      return {
        key: propertyName,
        values: propertyValues,
        comparisonStrategy: comparisonOperator,
        comparisonInverse: inverse
      };
    });

  const mappedExcluded = excludedAttributes
    .filter(a => a._type === attributeType)
    .map(sessionAttribute => {
      const {
        comparisonOperator,
        inverse,
        propertyName,
        propertyValues
      } = sessionAttribute;
      return {
        key: propertyName,
        values: propertyValues,
        comparisonStrategy: comparisonOperator,
        comparisonInverse: inverse
      };
    });
  return { include: mappedIncluded, exclude: mappedExcluded };
};

const buildProfileAttribute = attributes => {
  return attributes.map(attribute => {
    if (attribute.hasOwnProperty('fieldKey')) {
      const data = {
        key: attribute.fieldKey,
        values: attribute.selectedAttributeValues,
        comparisonStrategy:
          attribute.selectedDatatype === 'DoesNotExist'
            ? 'Exists'
            : attribute.selectedDatatype,
        comparisonInverse:
          attribute.selectedDatatype === 'DoesNotExist' ? true : false
      };
      return data;
    }
    return attribute;
  });
};
