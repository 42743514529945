import { createAction } from '@reduxjs/toolkit';
import {
  areSessionAttributesValid,
  buildFilter,
  MEETS,
  parseFilter
} from '../../../components/filters/filter-utils';
import * as itemTypes from '../../../items/item-types';
import {
  getItem as getItemInternal,
  saveItem as saveItemInternal
} from '../../../items/item.actions';
import { keyFromItemType } from '../../../routing-utils';
import { DEFAULT_END_DATE_STRING } from '../../campaign-utils';
import { buildTargeting } from './ad-group-utils';

export const receiveAdGroup = createAction('receiveAdGroup');
export const setAdGroupIsBusy = createAction('setAdGroupIsBusy');
export const modifyAdGroup = createAction('modifyAdGroup');
export const closeAdGroup = createAction('closeAdGroup');

const adCreativeModel = (id, name, params) => {
  return {
    id,
    name,
    type: itemTypes.AD_GROUP,
    [keyFromItemType(itemTypes.CAMPAIGN)]: params.campaignid,
    capStats: {
      overallConversionCap: 0,
      monthlyConversionCap: 0,
      dailyConversionCap: 0,
      overallRevenueCap: 0,
      monthlyRevenueCap: 0,
      dailyRevenueCap: 0
    }
  };
};

export const getItem = getItemInternal(
  adCreativeModel,
  receiveAdGroup,
  setAdGroupIsBusy,
  item => {
    const verticalIds = [];
    const excludedVerticalIds = [];
    const profileAttributes = [];
    const excludedProfileAttributes = [];
    let profileAttributesApply = MEETS[1].value;
    let excludedProfileAttributesApply = MEETS[1].value;
    // parse verticals separately
    if (item.targeting?.verticals.include?.length > 0) {
      verticalIds.push(...item.targeting.verticals?.include);
    }
    if (item.targeting?.verticals.exclude?.length > 0) {
      excludedVerticalIds.push(...item.targeting.verticals?.exclude);
    }
    if (item.targeting?.profileAttributeConditions?.include?.length > 0) {
      profileAttributes.push(
        ...item.targeting.profileAttributeConditions?.include
      );
    }
    if (item.targeting?.profileAttributeConditions?.exclude?.length > 0) {
      excludedProfileAttributes.push(
        ...item.targeting.profileAttributeConditions?.exclude
      );
    }
    if (item.targeting?.profileAttributeConditions?.includeMatchMode) {
      profileAttributesApply =
        item.targeting.profileAttributeConditions.includeMatchMode;
    }
    if (item.targeting?.profileAttributeConditions?.excludeMatchMode) {
      excludedProfileAttributesApply =
        item.targeting.profileAttributeConditions.excludeMatchMode;
    }

    item.targeting = {
      ...parseFilter(item.filter, true),
      verticalIds,
      excludedVerticalIds,
      profileAttributes,
      excludedProfileAttributes,
      profileAttributesApply,
      excludedProfileAttributesApply
    };
  }
);

export const saveItem = saveItemInternal(
  modifyAdGroup,
  item => {
    if (!item.name) {
      return 'Name is a required field.';
    }
    if (!item.tuneOfferId) {
      return 'Tune Offer Id is a required field.';
    }
    if (!item.offerUrl) {
      return 'Offer Url is a required field.';
    }
    if (!item.campaignPlacementId) {
      return 'Placement is required, please select a placement.';
    }
    if (!item.assignedCreatives) {
      return 'Creative is required, please select a creative.';
    }
    if (
      !item.duplicate &&
      (!item.targeting || !item.targeting.deviceTypes?.length > 0)
    ) {
      return 'Device type is required, please select a device type.';
    }
    if (
      !item.duplicate &&
      (!areSessionAttributesValid(item.targeting.sessionAttributes) ||
        !areSessionAttributesValid(item.targeting.excludedSessionAttributes))
    ) {
      return 'You have session attributes that are incomplete. Complete them or remove them before saving.';
    }
    if (
      item.useDynamicCpc &&
      (!item.dynamicCpcConversionEventId || !item.dynamicCpcTargetPayout)
    ) {
      return 'Conversion Event and Target payout are required when Dynamic CPC is enabled.';
    }
    if (
      item.rpiOverrideChecked &&
      item.rpiOverrideExpiry === DEFAULT_END_DATE_STRING
    ) {
      return 'RPI Override requires an Expiry date.';
    }
  },
  itemCopy => {
    itemCopy.netsuiteAgencyLineId =
      itemCopy.netsuiteAdvertiserLineItem?.agencyLineId ?? null;
    delete itemCopy.netsuiteAdvertiserLineItem;

    //build filter from sourceid, audiences, conditions, meets, locations
    if (itemCopy.duplicate && !itemCopy.targeting) {
      return;
    } else {
      itemCopy.filter = buildFilter(itemCopy.targeting);
      const newTargeting = buildTargeting(itemCopy.targeting);
      itemCopy.targeting = newTargeting;
    }
    delete itemCopy.duplicate;
    delete itemCopy.rpiOverrideChecked;
  }
);
